import React from 'react';

import { Tooltip } from 'antd';

const EventsCloud = ({
  lastXEvents,
  imgRef,
}: {
  lastXEvents: any;
  imgRef: any;
}) => {
  return (
    <>
      {lastXEvents &&
        lastXEvents['success']?.map((evt: any, index: number) => (
          <div
            key={index}
            className="event-dot success enabledClickable small"
            style={{
              left: evt.x * imgRef.current?.getBoundingClientRect().width - 5,
              top: evt.y * imgRef.current?.getBoundingClientRect().height - 5,
            }}
          />
        ))}
      {lastXEvents &&
        lastXEvents['fail']?.map((evt: any, index: number) => (
          <Tooltip title={evt.name} placement="top" key={index}>
            <div
              className="event-dot fail enabledClickable small"
              style={{
                left: evt.x * imgRef.current?.getBoundingClientRect().width - 5,
                top: evt.y * imgRef.current?.getBoundingClientRect().height - 5,
              }}
            />
          </Tooltip>
        ))}
    </>
  );
};
export default EventsCloud;
