import React, { useEffect, useState } from 'react';

import {
  Tooltip as ATooltip,
  Button,
  Col,
  ConfigProvider,
  Divider,
  Empty,
  Flex,
  Radio,
  Row,
  Skeleton,
  Table,
  Tree,
  TreeDataNode,
  TreeProps,
} from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './index.css';

import {
  // ClearSelectedGames,
  FilterApply,
  SetSelectedLastMatch,
} from 'actions/persona.actions';
import { AppStateType } from 'reducers';
// import { personaReducer } from 'reducers/persona.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { useAppDispatch } from 'store';
import { ActivityType } from 'types/playlist.types';

import { ReactComponent as DownOutlinedBlack } from '../../../../assets/img/icons/faDownOutlinedBlack.svg';

const prepareTreeStructure = (
  data: any,
  expandedKeys: string[],
): TreeDataNode[] => {
  return data.map((season: any) => {
    return {
      title: season.name,
      key: `0-${season.name}`,
      switcherIcon: (
        <DownOutlinedBlack
          className={`${expandedKeys.includes(`0-${season.id}`) ? 'rotate180' : 'rotate90'}`}
        />
      ),
      children: season.leagues.map((l: any) => ({
        title: l.name,
        key: `1-${season.name}-${l.tournament_id}`,
        data: l.matches,
      })),
    };
  });
};
interface GameDataType {
  key: React.Key;
  gameDetails: ActivityType;
}
export const PlayerStatisticsContent = ({
  hide,
  firstSeason,
}: {
  hide: () => void;
  firstSeason: string;
  isOpen: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const [gamesTableSource, setGamesTableSource] = useState<GameDataType[]>([]);
  const {
    personaMatches,
    state,
    selectedLastMatch,
    selectedMatches,
    appliedMatches,
    // appliedSelectedMatches,
    appliedSeasons,
    appliedTournaments,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    setAppliedSeasons,
    setSelectedTournaments,
    setAppliedTournaments,
    setAppliedLastMatches,
    setAppliedMatches,
    setSelectedMatches,
  } = teamPlayerStatsReducer.actions;
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const preparedTreeStructure = prepareTreeStructure(
    personaMatches,
    expandedKeys,
  );
  // const toggleSelectedMatch = (match: any) => {
  //   let newMatches: any[];
  //   if (selectedMatches.includes(match)) {
  //     newMatches = selectedMatches.filter((item) => item !== match);
  //   } else {
  //     newMatches = [...selectedMatches, match];
  //   }
  //   return newMatches;
  // };
  const { setSelectedMatchesStats } = teamPlayerReducer.actions;
  const populateLastMatches = () => {
    if (selectedLastMatch) {
      let matches: any[] = [];
      console.log('preparedTreeStructure');
      console.log(preparedTreeStructure);
      preparedTreeStructure.forEach((season: TreeDataNode) => {
        season.children?.forEach((child: any) => {
          child.data.forEach((match: any) => {
            matches.push({
              key: match.id,
              gameDetails: match,
            });
          });
        });
      });
      matches = matches.sort((a: any, b: any) =>
        dayjs(b.gameDetails.date).diff(dayjs(a.gameDetails.date)),
      );
      if (selectedLastMatch === '5') {
        matches = matches.slice(0, 5);
      } else if (selectedLastMatch === '10') {
        matches = matches.slice(0, 10);
      }
      setGamesTableSource(matches);
      console.log('3');
      setSelectedRowKeys(matches.map((match: any) => match.key));
      dispatch(setSelectedMatches(matches));
    }
  };
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('2');
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const initCheckedSeasons = () => {
    if ((appliedSeasons[0] || firstSeason) && !selectedLastMatch) {
      const checkedInitialSeasons = [
        `1-${appliedSeasons[0] || firstSeason}-${
          appliedTournaments[0] || personaMatches[0]?.leagues[0].tournament_id
        }`,
      ];
      setExpandedKeys([`0-${appliedSeasons[0] || firstSeason}`]);
      helper(checkedInitialSeasons);
      return checkedInitialSeasons;
    } else if (selectedLastMatch) {
      populateLastMatches();
    }
    return [];
  };
  const [checkedSeasons, setCheckedSeasons] = useState<any[]>([]);
  const helper = (checkedKeys: any) => {
    let flMatches: any = [];
    checkedKeys
      .filter((key: any) => key.length > 3 && key.indexOf('-') !== -1)
      .forEach((key: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const split = key.split('-');
        let index;
        let seasonId;
        let LeagueId: any;
        if (split.length === 2) {
          [index, seasonId] = split;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          [index, seasonId, LeagueId] = split;
        }
        const matches = preparedTreeStructure
          .filter((season: TreeDataNode) => {
            return season.key === `0-${seasonId}`;
          })[0]
          .children?.filter((child: any) => {
            return child.key === `1-${seasonId}-${LeagueId}`;
          })[0];
        if (matches) {
          flMatches = flMatches
            //@ts-ignore
            .concat(matches.data || [])
            .sort((a: any, b: any) => dayjs(b.date).diff(dayjs(a.date)));
        }
      });
    const tableMatches = flMatches.map((match: any) => {
      return {
        key: match.id,
        gameDetails: match,
      };
    });
    setGamesTableSource(tableMatches);
    console.log('1');
    setSelectedRowKeys(tableMatches.map((match: any) => match.key));
    // dispatch(ClearSelectedGames());
    dispatch(setSelectedMatches(flMatches));
    dispatch(
      setSelectedMatchesStats(tableMatches.map((match: any) => match.key)),
    );
  };
  useEffect(() => {
    setCheckedSeasons(initCheckedSeasons());
  }, []);
  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    dispatch(SetSelectedLastMatch(null));
    if (Array.isArray(checkedKeys)) {
      helper(checkedKeys);
      dispatch(
        setSelectedTournaments(
          checkedKeys.filter((el: any) => el.split('-').length > 2),
        ),
      );
      setCheckedSeasons(checkedKeys);
    } else {
      console.log('checkedKeys is not an array');
    }
  };
  const onLastMatchRadioChange = async (e: any) => {
    setCheckedSeasons([]);
    await dispatch(SetSelectedLastMatch(e.target.value));
  };
  useEffect(() => {
    populateLastMatches();
  }, [selectedLastMatch]);

  useEffect(() => {
    console.log('UE');
    dispatch(FilterApply(checkedSeasons, selectedRowKeys));
  }, [selectedRowKeys]);
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="personSettingPopover">
      <div className="personSettingHeader">
        <Row>
          <Col span={12}>
            <div>{t('Select matches')}</div>
          </Col>
          <Col span={12}>
            <Flex justify="end" gap={8}>
              <Button
                type="default"
                onClick={() => {
                  hide();
                  setCheckedSeasons(appliedSeasons);
                  dispatch(SetSelectedLastMatch(null));
                  setSelectedRowKeys(appliedMatches);
                }}
              >
                {t('Cancel')}
              </Button>
              <Button
                type="primary"
                disabled={selectedRowKeys.length === 0}
                onClick={() => {
                  // dispatch(FilterApply(checkedSeasons));
                  dispatch(setAppliedLastMatches(selectedLastMatch));
                  dispatch(SetSelectedLastMatch(null));
                  dispatch(setAppliedMatches(selectedRowKeys));
                  dispatch(
                    setAppliedSeasons(
                      checkedSeasons
                        .filter((id: string) => {
                          return id.split('-').length === 2;
                        })
                        .map((id: string) => {
                          return id.split('-')[1];
                        }),
                    ),
                  );
                  dispatch(
                    setAppliedTournaments(
                      checkedSeasons.map((id: string) => {
                        if (id.split('-').length > 2) {
                          return id.split('-')[2];
                        }
                      }),
                    ),
                  );
                  hide();
                }}
              >
                {t('Apply a')}
              </Button>
            </Flex>
          </Col>
        </Row>
      </div>
      <Divider />
      <Row gutter={24} className="personFilterContainer">
        <Col span={8} className="personFilterVerticalDivider">
          <Radio.Group
            onChange={onLastMatchRadioChange}
            value={selectedLastMatch}
          >
            <Radio.Button className="personMatchCountSelect" value="5">
              {t('Last 5 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="10">
              {t('Last 10 matches')}
            </Radio.Button>
            <Radio.Button className="personMatchCountSelect" value="all">
              {t('All matches')}
            </Radio.Button>
          </Radio.Group>
          <Divider />
          <Tree
            className="treeSelect"
            checkable
            selectable={false}
            blockNode
            checkedKeys={checkedSeasons}
            expandedKeys={expandedKeys}
            onExpand={(expaned) => {
              setExpandedKeys(expaned);
            }}
            // switcherIcon={
            //   <DownOutlinedBlack
            //   // rotate={'rotate90'}
            //   // className={'rotate90'}
            //   // className={`${checkedSeasons.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
            //   />
            // }
            onCheck={onCheck}
            treeData={preparedTreeStructure}
          />
        </Col>
        <Col span={16} className="personFilterMatches">
          <Skeleton
            active
            loading={state === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />
          <Skeleton
            active
            loading={state === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />
          <Skeleton
            active
            loading={state === 'loading'}
            paragraph={{ rows: 1, width: ['100%'] }}
          />

          {state === 'loaded' && (
            <>
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: 'var(--colorBgContainer)',
                      borderColor: 'none',
                      cellPaddingBlock: 8,
                      cellPaddingInline: 6,
                      rowSelectedBg: 'none',
                    },
                  },
                }}
              >
                <Table<GameDataType>
                  rowSelection={rowSelection}
                  dataSource={gamesTableSource}
                  pagination={false}
                  bordered={false}
                  rowHoverable={false}
                  rowClassName={'enabledClickable'}
                  locale={{
                    emptyText: (
                      <Empty
                        description={t(
                          'Please, select a season and a tournament',
                        )}
                      />
                    ),
                  }}
                  onRow={(record) => ({
                    onClick: () => {
                      const selectedKey = record.key;
                      console.log('checked');
                      setSelectedRowKeys((prevSelectedRowKeys) => {
                        // Check if the clicked row is already selected
                        if (prevSelectedRowKeys.includes(selectedKey)) {
                          // If selected, deselect it
                          return prevSelectedRowKeys.filter(
                            (key) => key !== selectedKey,
                          );
                        } else {
                          // Otherwise, add it to the selection
                          return [...prevSelectedRowKeys, selectedKey];
                        }
                      });
                    },
                  })}
                >
                  <Column
                    render={(match: any) => {
                      return (
                        <div className="oy-h">
                          <div
                            className={`games_filter_game_block_name ${selectedMatches.includes(match) ? 'color-text-heading' : 'color-text-label'}`}
                          >
                            {match.gameDetails.home_team}{' '}
                            {match.gameDetails.score?.score.replace('-', ':')}{' '}
                            {match.gameDetails.away_team}
                          </div>
                          <div
                            className={`games_filter_game_block_info ${selectedMatches.includes(match) ? 'color-text-label' : 'color-description'}`}
                          >
                            <div>{match.gameDetails.date}</div>
                            {match.gameDetails.country && (
                              <>
                                <div>
                                  <Divider
                                    type="vertical"
                                    style={{ margin: '0 0 0 0' }}
                                  />
                                </div>
                                <ATooltip
                                  title={match.gameDetails.country}
                                  arrow={true}
                                >
                                  <div className="elipsis-text">
                                    {match.gameDetails.country}
                                  </div>
                                </ATooltip>
                                <div>
                                  <Divider
                                    type="vertical"
                                    style={{ margin: '0 0 0 0' }}
                                  />
                                </div>
                                <ATooltip
                                  title={match.gameDetails.country}
                                  arrow={true}
                                >
                                  <div className="elipsis-text">
                                    {match.gameDetails.league}
                                  </div>
                                </ATooltip>
                              </>
                            )}
                            {match.gameDetails.tournament && (
                              <>
                                <div>
                                  <div className="divider"></div>
                                </div>
                                <ATooltip
                                  title={match.gameDetails.tournament}
                                  arrow={true}
                                >
                                  <div>{match.gameDetails.tournament}</div>
                                </ATooltip>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    }}
                  />
                </Table>
              </ConfigProvider>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
