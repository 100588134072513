/* eslint-disable indent */
import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { ActivityType } from 'types/playlist.types';

const {
  setState,
  setTeamPlayerMatches,
  setSelectedLastMatch,
  setSelectedMatches,
  setTeamPlayerStats,
  setStatsState,
} = teamPlayerStatsReducer.actions;

export const SetStatsState =
  (
    state: 'init' | 'loading' | 'loaded' | 'error',
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setStatsState(state));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const GetPersonaAllMathes =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    try {
      console.log('GetPersonaAllMathes');
      const teamId = getState().teamPlayerReducer.openedTeamPlayer?.team?.id;
      const selectedPlayers = getState().teamPlayerReducer.openedTeamPlayer?.id;
      console.log(teamId, selectedPlayers);
      dispatch(setState('loading'));
      try {
        const data = await EventsAPI.getTeamPlayerGamesWithSeasons(
          selectedPlayers,
          teamId,
          true,
        );
        if (data.data) {
          dispatch(setTeamPlayerMatches(data.data));
        }
        dispatch(setState('loaded'));
      } catch (e) {
        console.error('first error', e);
      }
    } catch (e) {
      console.error('first error', e);
    }
  };

export const SetSelectedLastMatch =
  (
    count: number | string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedLastMatch(count));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const SelectGame =
  (
    match: ActivityType,
    setCheckedSeasons: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    try {
      const selectedMatches = getState().teamPlayerStatsReducer.selectedMatches;
      if (selectedMatches.includes(match)) {
        setCheckedSeasons((prev: any) =>
          prev.filter((key: string) => key !== ''),
        );
        dispatch(
          setSelectedMatches(selectedMatches.filter((item) => item !== match)),
        );
      } else {
        dispatch(setSelectedMatches([...selectedMatches, match]));
      }
    } catch (e) {
      console.error('first error', e);
    }
  };

export const ClearSelectedGames =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(setSelectedMatches([]));
    } catch (e) {
      console.error('first error', e);
    }
  };

export const FilterApply =
  (
    checkedSeasons: string[],
    selectedMatchesIds: any[],
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch, getState) => {
    console.log('filter applied');
    dispatch(setStatsState('loading'));
    try {
      let seasonId = 'manual';
      if (checkedSeasons.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [index, seasonName, tournamentId] = checkedSeasons[0].split('-');
        const tournament = getState()
          .teamPlayerStatsReducer.personaMatches.filter(
            (season: any) => season.name === seasonName,
          )[0]
          .leagues.filter(
            (league: any) =>
              league.tournament_id.toString() === tournamentId.toString(),
          )[0];
        if (tournament.matches.length === selectedMatchesIds.length) {
          seasonId = tournament.season_id;
          selectedMatchesIds = [];
        }
      }
      const playerId = getState().teamPlayerReducer.openedTeamPlayer?.id;
      // alert('1');
      const response = await EventsAPI.getPlayerGamesStatsByPost(
        seasonId,
        playerId,
        '', //selectedPositions || '',
        selectedMatchesIds,
        false,
      );
      const finalStats: any = [];
      getState().teamPlayerStatsReducer.teamPlayerStats.forEach((stat) => {
        const populatedStat: any = {
          label: stat.label,
          key: stat.key,
          stat: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const childPopulatedStat = {
              ...childStat,
              stat: response.data.stats.filter(
                (s: any) => s.key === childStat.key,
              )[0],
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });

      dispatch(setTeamPlayerStats(finalStats));
      dispatch(setStatsState('loaded'));
    } catch (e) {
      dispatch(setStatsState('error'));
      console.error('first error', e);
    }
  };
