import { createSlice } from '@reduxjs/toolkit';

import { personaPercentilesType } from 'types/types';

type InitialStateType = {
  state: 'init' | 'loading' | 'loaded' | 'error';
  selectedLastMatch: number | string | null;
  appliedLastMatch: number | string | null;
  personaMatches: any;
  selectedMatches: any[];
  selectedSeasons: string[];
  selectedTournaments: string[];
  appliedMatches: string[];
  appliedSeasons: string[];
  appliedTournaments: string[];
  personaPercentiles: personaPercentilesType[];
  teamPlayerStats: any[];
  statsState: 'init' | 'loading' | 'loaded' | 'error';
};
const initialState: InitialStateType = {
  state: 'init',
  selectedLastMatch: null,
  appliedLastMatch: null,
  personaMatches: [],
  selectedMatches: [],
  personaPercentiles: [],
  statsState: 'init',
  selectedSeasons: [],
  selectedTournaments: [],
  appliedMatches: [],
  appliedSeasons: [],
  appliedTournaments: [],
  teamPlayerStats: [],
};

export const teamPlayerStatsReducer = createSlice({
  name: 'personaReducer',
  initialState: initialState,
  reducers: {
    setTeamPlayerStats(state, action) {
      state.teamPlayerStats = action.payload;
    },
    setState(state, action) {
      state.state = action.payload;
    },
    setStatsState(state, action) {
      state.statsState = action.payload;
    },
    setSelectedLastMatch(state, action) {
      state.selectedLastMatch = action.payload;
    },
    setTeamPlayerMatches(state, action) {
      state.personaMatches = action.payload;
    },
    setSelectedMatches(state, action) {
      state.selectedMatches = action.payload;
    },
    toggleSelectedMatch(state, action) {
      if (state.selectedMatches.includes(action.payload)) {
        state.selectedMatches = state.selectedMatches.filter(
          (item) => item !== action.payload,
        );
      } else {
        state.selectedMatches.push(action.payload);
      }
    },
    setPesonaPercentiles(state, action) {
      state.personaPercentiles = action.payload;
    },
    setSelectedSeasons(state, action) {
      state.selectedSeasons = action.payload;
    },
    setSelectedTournaments(state, action) {
      state.selectedTournaments = action.payload;
    },
    setAppliedMatches(state, action) {
      state.appliedMatches = action.payload;
    },
    setAppliedSeasons(state, action) {
      state.appliedSeasons = action.payload;
    },
    setAppliedTournaments(state, action) {
      state.appliedTournaments = action.payload;
    },
    setAppliedLastMatches(state, action) {
      state.appliedLastMatch = action.payload;
    },
  },
});
