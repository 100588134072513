/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Tooltip as ATooltip,
  Checkbox,
  ConfigProvider,
  Divider,
  Flex,
  Popover,
  Select,
  Skeleton,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import './index.css';

import {
  GetPersonaAllMathes,
  SetSelectedLastMatch,
  SetStatsState,
} from 'actions/persona.actions';
import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import GameSelectSidePanel from 'components/GameSelectSidePane';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { teamPlayerStatsReducer } from 'reducers/teamPlayerStats.reducer';
import { useAppDispatch } from 'store';

// eslint-disable-next-line import/order

import AverageStatValueTag from './AverageStatValueTag';
import ConsolidatedStatValueTag from './ConsolidatedStatValueTag';
import { makeLink, makeStatsPlaceholders } from './const';
import { PlayerStatisticsContent } from './SettingPopover';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as DownOutlinedBlack } from '../../../assets/img/icons/faDownOutlinedBlack.svg';

// eslint-disable-next-line import/order

const PlayerStatisticsBlock = () => {
  const [t] = useTranslation();
  const STATS_PLACEHOLDER = makeStatsPlaceholders(t);
  const {
    openedTeamPlayer,
    selectedMatchesStats,
    positions,
    positionsDurations,
    selectedPosition,
  } = useSelector((state: AppStateType) => state.teamPlayerReducer);
  const {
    selectedTournaments,
    personaMatches,
    appliedLastMatch,
    statsState,
    personaPercentiles,
    teamPlayerStats,
  } = useSelector((state: AppStateType) => state.teamPlayerStatsReducer);
  const { setTeamPlayerStats } = teamPlayerStatsReducer.actions;
  const { setSelectedPosition } = teamPlayerReducer.actions;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const { playerId } = useParams();
  // const [loadingPercentiles, setLoadingPercentiles] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<string | number>();
  const [selectedSeasonLabel, setSelectedSeasonLabel] = useState<string>();
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const [playerStats, setPlayerStats] = useState<any[]>(STATS_PLACEHOLDER);
  const [timeOnField, setTimeOnField] = useState<number>(0);
  const [isLong, setIsLong] = useState<boolean>(false);
  const PopoverHandlerRef = React.createRef<any>();
  useEffect(() => {
    dispatch(setTeamPlayerStats(makeStatsPlaceholders(t)));
  }, []);
  const getSeasonStats = async (seasonId: string | number) => {
    // setPlayerStats(STATS_PLACEHOLDER);
    // alert('12');
    console.log('getSeasonStats');
    const response = await EventsAPI.getPlayerGamesStatsByPost(
      seasonId.toString(),
      playerId || '',
      selectedPosition || '',
      selectedMatchesStats,
      selectedPosition
        ? positionsDurations[selectedPosition] > 90 * 60 * 1000
        : false,
    );
    const finalStats: any = [];
    setTimeOnField(response.data.time_on_field);
    teamPlayerStats.forEach((stat) => {
      const populatedStat: any = {
        label: stat.label,
        key: stat.key,
        stat: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
      };
      if (stat.children?.length > 0) {
        populatedStat.children = [];
        stat.children.forEach((childStat: any) => {
          const childPopulatedStat = {
            ...childStat,
            stat: response.data.stats.filter(
              (s: any) => s.key === childStat.key,
            )[0],
          };
          populatedStat.children.push(childPopulatedStat);
        });
      }
      finalStats.push(populatedStat);
    });
    console.log('setPlayerStats finalStats');
    console.log(finalStats);
    setPlayerStats(finalStats);
    dispatch(setTeamPlayerStats(finalStats));
  };
  const getSeasonPercentiles = async (seasonId: string, cancelToken: any) => {
    dispatch(SetStatsState('loading'));
    const response = await EventsAPI.getPlayerPercentilesBySeason(
      seasonId,
      playerId || '',
      selectedPosition || '',
      selectedMatchesStats,
      selectedPosition
        ? positionsDurations[selectedPosition] > 90 * 60 * 1000
        : false,
      cancelToken,
    );
    const finalStats: any = [];
    if (response?.data) {
      playerStats.forEach((stat) => {
        const populatedStat: any = {
          ...stat,
          stat: {
            ...stat.stat,
            pth: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
          },
        };
        if (stat.children?.length > 0) {
          populatedStat.children = [];
          stat.children.forEach((childStat: any) => {
            const s = response.data.stats.filter(
              (s: any) => s.key === childStat.key,
            )[0];
            const childPopulatedStat = {
              ...childStat,
              stat: {
                ...childStat,
                pth: s.pth,
              },
            };
            populatedStat.children.push(childPopulatedStat);
          });
        }
        finalStats.push(populatedStat);
      });
      setPlayerStats(finalStats);
    }
    dispatch(SetStatsState('loaded'));
  };
  useEffect(() => {
    if (selectedTournaments.length === 1) {
      const tournamentId = selectedTournaments[0];
      const tournamentMatchIds = personaMatches
        .filter((season: any) => season.name === tournamentId.split('-')[1])[0]
        .leagues.filter((league: any) => {
          return league.tournament_id.toString() === tournamentId.split('-')[2];
        })[0]
        .matches.map((match: any) => match.id);
      const selectedMatchesIds = selectedMatchesStats.map(
        (match: any) => match.key,
      );
      if (tournamentMatchIds === selectedMatchesIds) {
        setSelectedSeason(tournamentId);
      } else {
        setSelectedSeason('manual');
      }
    }
  }, [selectedTournaments]);
  useEffect(() => {
    if (playerId) {
      if (
        selectedSeason &&
        selectedSeason !== 'manual' &&
        selectedMatchesStats.length === 0
      ) {
        getSeasonStats(selectedSeason).then();
      } else if (selectedMatchesStats.length > 0) {
        getSeasonStats('manual').then();
      }
    }
  }, [
    playerId,
    selectedSeason,
    // selectedMatchesStats
  ]);
  useEffect(() => {
    if (
      playerId &&
      selectedSeason &&
      selectedSeason !== 'manual' &&
      selectedMatchesStats.length === 0
    ) {
      clearPercentiles();
      if (
        selectedPosition &&
        positionsDurations[selectedPosition] > 90 * 60 * 1000
      ) {
        const source = axios.CancelToken.source();
        getSeasonPercentiles(selectedSeason.toString(), source.token).then();
        return () => {
          source.cancel('Operation canceled by the user.');
        };
      }
    }
  }, [selectedPosition]);
  useEffect(() => {
    if (playerId) {
      if (personaMatches.length) {
        console.log('c');
        setSelectedSeason(personaMatches[0].leagues[0].season_id);
        setSelectedSeasonLabel(personaMatches[0].name);
      }
      const checkContentHeight = () => {
        if (PopoverHandlerRef.current) {
          setIsLong(PopoverHandlerRef.current.scrollWidth > 180);
        }
      };
      checkContentHeight();
    }
  }, [personaMatches]);
  const clearPercentiles = () => {
    const finalStats: any = [];
    playerStats.forEach((stat) => {
      const populatedStat: any = {
        ...stat,
        stat: {
          ...stat.stat,
          pth: null,
        },
      };
      if (stat.children?.length > 0) {
        populatedStat.children = [];
        stat.children.forEach((childStat: any) => {
          const childPopulatedStat = {
            ...childStat,
            stat: {
              ...childStat,
              pth: null,
            },
          };
          populatedStat.children.push(childPopulatedStat);
        });
      }
      finalStats.push(populatedStat);
    });
    console.log('setPlayerStats finalStats4');
    console.log(finalStats);
    setPlayerStats(finalStats);
  };

  useEffect(() => {
    if (openedTeamPlayer) {
      // console.log(openedTeamPlayer);
      dispatch(GetPersonaAllMathes());
    }
  }, [openedTeamPlayer]);

  const toggleSelectedMetric = async (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev) =>
          prev.filter((el) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev) => [
          ...prev.filter((p) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const makeLinkAndNavigate = (stat: any) => {
    const metricStringSuccess = `${stat.key}_success`;
    const metricStringFail = `${stat.key}_fail`;
    let finalMetric = [stat];
    if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringSuccess)
    ) {
      finalMetric = [{ key: metricStringSuccess }];
    } else if (
      selectedMetricFilters &&
      selectedMetricFilters.includes(metricStringFail)
    ) {
      finalMetric = [{ key: metricStringFail }];
    }
    window.open(
      makeLink(
        [{ id: null }],
        openedTeamPlayer.team,
        finalMetric,
        undefined,
        selectedSeason,
        playerId,
        selectedPosition,
      ),
      '_blank',
    );
  };
  const renderMetricFilters = (metric: string, metricObj: any) => {
    const metricStringSuccess = `${metric}_success`;
    const metricStringFail = `${metric}_fail`;
    if (
      (selectedMetricFilters &&
        (selectedMetricFilters.includes(metricStringSuccess) ||
          selectedMetricFilters.includes(metricStringFail))) ||
      hoveredMetricFilter.includes(metric)
    ) {
      return (
        <Flex gap={2}>
          <Flex className="success-checkbox" justify="center">
            <ConfigProvider
              theme={{
                token: {},
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringSuccess)}
                // disabled={loadingCoordinates}
                onClick={() => {
                  toggleSelectedMetric(metricStringSuccess, metricStringFail);
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex className="fail-checkbox" justify="center">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: 'grey',
                },
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringFail)}
                // disabled={loadingCoordinates}
                onClick={() => {
                  toggleSelectedMetric(metricStringFail, metricStringSuccess);
                }}
              />
            </ConfigProvider>
          </Flex>
        </Flex>
      );
    } else {
      return <Flex children={undefined}></Flex>;
    }
  };
  const hide = () => {
    setOpen((prev) => false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen((prev) => newOpen);
  };
  return (
    <div className="block-wrapper right">
      <Popover
        placement="left"
        // title={t('Statistics')}
        content={
          <PlayerStatisticsContent
            hide={hide}
            isOpen={open}
            firstSeason={
              personaMatches.length > 0 ? `${personaMatches[0].name}` : ''
            }
          />
        }
        open={open}
        className="some-class"
        // trigger="click"
        trigger="contextMenu"
        onOpenChange={handleOpenChange}
      >
        <div className="block-title">
          <div>{t('Statistics')}</div>
          <Flex align="center" gap={8}>
            {positions.length > 0 && (
              <ConfigProvider
                theme={{
                  token: {
                    colorText: 'var(--colorPrimaryBase, #1668DC);',
                  },
                  components: {
                    Select: {
                      optionSelectedColor:
                        'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                    },
                  },
                }}
              >
                <Select
                  placeholder={
                    <div className="select-placeholder">
                      {t('All positions')}
                    </div>
                  }
                  variant="borderless"
                  className={'hasSelection'}
                  value={selectedPosition}
                  onSelect={(value: string) => {
                    if (value === 'clear') {
                      dispatch(setSelectedPosition(null));
                    } else {
                      dispatch(setSelectedPosition(value));
                    }
                  }}
                  popupMatchSelectWidth={false}
                  placement="bottomRight"
                >
                  <Select.Option
                    style={{
                      mpaddingTop: 10,
                      borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                      borderRaius: 0,
                      color: 'black',
                    }}
                    className="enabledClickable"
                    key={0}
                    onClick={(e: any) => {
                      e.preventDefault();
                    }}
                    label={''}
                    value={'clear'}
                  >
                    {t('Clear filter')}
                  </Select.Option>
                  {positions.map((position: any, index: number) => (
                    <Select.Option
                      key={index + 1}
                      label={position.value}
                      value={position.value}
                      style={{
                        color: 'black',
                      }}
                    >
                      {position.label}
                    </Select.Option>
                  ))}
                </Select>
              </ConfigProvider>
            )}
            <div
              ref={PopoverHandlerRef}
              className="playerStatsPopoverHandler"
              style={{ color: 'var(--colorTextLabel, rgba(0, 0, 0, 0.65))' }}
              onClick={() => {
                setOpen((prev: boolean) => true);
                dispatch(SetSelectedLastMatch(appliedLastMatch));
              }}
            >
              {isLong && (
                <ATooltip
                  title={
                    personaMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                  arrow={true}
                >
                  <div>
                    {
                      personaMatches.filter(
                        (s: any) => s.name === selectedSeasonLabel,
                      )[0]?.name
                    }
                  </div>
                </ATooltip>
              )}
              {!isLong && (
                <div>
                  {
                    personaMatches.filter(
                      (s: any) => s.name === selectedSeasonLabel,
                    )[0]?.name
                  }
                </div>
              )}
            </div>
          </Flex>
        </div>
      </Popover>
      <div className="block-content">
        <div className="block-column block-colum-player-stats">
          <Flex className="block-column-title" justify="space-between">
            <Flex flex={1} className="stats-name">
              {t('Actions')}
            </Flex>
            <Flex flex={1} justify="space-between">
              <div className="stats-header">{t('Total')}</div>
              <div className="stats-header">{t('90 min')}</div>
            </Flex>
          </Flex>
          <Divider style={{ margin: '0 0 8px 0', width: 'auto' }} />
          <Flex
            vertical
            style={{ margin: '0 16px 8px 16px', alignSelf: 'stretch' }}
          >
            {teamPlayerStats?.map((stat: any, index: number) => {
              return (
                <>
                  <Flex
                    justify="flex-start"
                    style={{ width: '100%', margin: '2px 0px' }}
                  >
                    <Flex
                      justify="space-between"
                      style={{ width: '100%' }}
                      align="center"
                      gap={8}
                      onMouseEnter={(e: any) => {
                        e.preventDefault();
                        if (
                          stat.stat.percent !== null &&
                          stat.stat.percent !== undefined
                        ) {
                          setHoveredMetricFilters((prev) => [
                            ...prev,
                            stat.key,
                          ]);
                        }
                      }}
                      onMouseLeave={() => {
                        setHoveredMetricFilters((prev) =>
                          prev.filter((el: string) => el !== stat.key),
                        );
                      }}
                    >
                      <Flex
                        style={{
                          marginLeft: 0,
                          overflow: 'hidden',
                        }}
                        align="center"
                        justify="flex-start"
                        gap={8}
                        flex={1}
                        className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                        onClick={() => {
                          if (
                            stat.children?.length &&
                            expandedStats.includes(stat.key)
                          ) {
                            setExpandedStats((prev) =>
                              prev.filter((item: string) => item !== stat.key),
                            );
                          } else {
                            setExpandedStats((prev) => [...prev, stat.key]);
                          }
                        }}
                      >
                        {stat.label}
                        {stat.children?.length && (
                          <DownOutlinedBlack
                            className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                          />
                        )}
                      </Flex>
                      <Flex>{renderMetricFilters(stat.key, stat)}</Flex>
                      <Flex gap={8}>
                        {stat.stat === 'loading' || statsState == 'loading' ? (
                          <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                            <Skeleton.Button
                              active
                              style={{ minWidth: 70, minHeight: 38 }}
                            ></Skeleton.Button>
                          </div>
                        ) : (
                          <ConsolidatedStatValueTag
                            stat={stat.stat || stat}
                            makeLink={makeLinkAndNavigate}
                            loadingPercentiles={false}
                            selectedMetricFilters={selectedMetricFilters}
                            selectedPositions={selectedPosition}
                          />
                        )}
                        {stat.stat === 'loading' ? (
                          <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                            <Skeleton.Button
                              active
                              style={{ minWidth: 90, minHeight: 38 }}
                            />
                          </div>
                        ) : (
                          <AverageStatValueTag
                            stat={stat.stat}
                            loadingPercentiles={false}
                            selectedMetricFilters={selectedMetricFilters}
                            selectedPositions={selectedPosition}
                            timeOnField={timeOnField}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  {stat.children?.length > 0 && (
                    <Flex
                      vertical
                      style={{ width: '100%' }}
                      gap={8}
                      className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                    >
                      {stat.children.map((child: any, index: number) => {
                        return stat.stat === 'loading' ? (
                          <Skeleton.Button
                            key={index}
                            active
                            shape={'round'}
                            style={{ width: 75, height: 32 }}
                          ></Skeleton.Button>
                        ) : (
                          <Flex
                            key={index}
                            justify="space-between"
                            style={{ width: '100%' }}
                            gap={8}
                          >
                            <Flex
                              style={{
                                marginLeft: 20,
                                overflow: 'hidden',
                              }}
                              align="center"
                              justify="space-between"
                              gap={8}
                              flex={1}
                              onMouseEnter={(e: any) => {
                                e.preventDefault();
                                if (
                                  child.stat.percent !== null &&
                                  child.stat.percent !== undefined
                                ) {
                                  setHoveredMetricFilters((prev) => [
                                    ...prev,
                                    child.key,
                                  ]);
                                }
                              }}
                              onMouseLeave={() => {
                                setHoveredMetricFilters((prev) =>
                                  prev.filter((el: string) => el !== child.key),
                                );
                              }}
                            >
                              <Flex>{child.label}</Flex>
                              {renderMetricFilters(child.key, child)}
                            </Flex>
                            <Flex gap={8}>
                              <ConsolidatedStatValueTag
                                stat={child | child.stat}
                                loadingPercentiles={false}
                                selectedMetricFilters={selectedMetricFilters}
                                makeLink={makeLinkAndNavigate}
                                selectedPositions={selectedPosition}
                              />
                              <AverageStatValueTag
                                stat={child.stat}
                                loadingPercentiles={false}
                                selectedMetricFilters={selectedMetricFilters}
                                selectedPositions={selectedPosition}
                                timeOnField={timeOnField}
                              />
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                </>
              );
            })}
          </Flex>
        </div>
      </div>
      <GameSelectSidePanel />
    </div>
  );
};
export default PlayerStatisticsBlock;
